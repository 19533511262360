import React from 'react'
import { Show, SimpleShowLayout, TextField, ReferenceManyField, Labeled } from 'react-admin'

import GridList from '../styles/GridList'

const StylePackShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="description" />

      <Labeled label="Styles" />
      <ReferenceManyField
        addLabel={false}
        reference="styles"
        target="style_pack_id"
        sort={{ field: 'id', order: 'DESC' }}
        perPage={500}
      >
        <GridList />
      </ReferenceManyField>
    </SimpleShowLayout>
  </Show>
)

export default StylePackShow
