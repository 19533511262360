import React from 'react'
import {
  Create, SimpleForm, TextInput, ReferenceArrayInput, SelectArrayInput, ImageInput, ImageField, required,
} from 'react-admin'

const UserCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="email" validate={required()} />
      <TextInput source="password" type="password" validate={required()} />
      <ReferenceArrayInput label="Events" source="event_ids" reference="events" perPage={1000}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ImageInput source="image" label="Image" accept="image/*">
        <ImageField source="src" title="name" />
      </ImageInput>
    </SimpleForm>
  </Create>
)

export default UserCreate
