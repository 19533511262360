import React from 'react'
import {
  Edit, SimpleForm, TextInput, DisabledInput, ImageInput, ImageField, ReferenceArrayInput, SelectArrayInput, BooleanInput, DateInput, LongTextInput, required,
} from 'react-admin'

import EventTitle from './EventTitle'

const EventEdit = props => (
  <Edit {...props} title={<EventTitle />} undoable={false}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="name" validate={required()} />
      <DateInput source="event_date" validate={required()} />
      <TextInput source="street" />
      <TextInput source="suite" />
      <TextInput source="city" />
      <TextInput source="state" />
      <TextInput source="zip_code" />
      <TextInput source="email" />
      <BooleanInput source="printer_activated" />
      <BooleanInput source="active" />
      <ReferenceArrayInput label="Style Packs" source="style_pack_ids" reference="style_packs" perPage={1000}>
        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Styles" source="style_ids" reference="styles" perPage={1000}>
        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput>
      <TextInput source="tag_info" />
      <TextInput source="tag_line" />
      <TextInput source="email_subject" />
      <LongTextInput source="email_body" />

      <ImageInput source="image" label="Image" accept="image/*">
        <ImageField source="src" title="name" />
      </ImageInput>
      <ImageField source="image_path" label="" />

      <ImageInput source="company_logo" accept="image/*">
        <ImageField source="src" title="company_logo_name" />
      </ImageInput>
      <ImageField source="company_logo_path" label="" />
    </SimpleForm>
  </Edit>
)

export default EventEdit
