import StyleIcon from '@material-ui/icons/Style'
import StyleList from './StyleList'
import StyleShow from './StyleShow'
import StyleEdit from './StyleEdit'
import StyleCreate from './StyleCreate'

export default {
  list: StyleList,
  show: StyleShow,
  edit: StyleEdit,
  create: StyleCreate,
  icon: StyleIcon,
}
