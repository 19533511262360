/* eslint-disable camelcase */
import React from 'react'
import { connect } from 'react-redux'
import { MenuItemLink, getResources, WithPermissions } from 'react-admin'
import { withRouter } from 'react-router-dom'
import events from '../events'
import users from '../users'
import styles from '../styles'
import style_packs from '../style_packs'

const Menu = ({ onMenuClick }) => ( // eslint-disable-line react/prop-types
  <div>
    <WithPermissions
      render={({ permissions }) => (
        [
          (permissions === 'admin' || permissions === 'superadmin')
          && (
          <MenuItemLink
            key="m1"
            to="/users"
            primaryText="Users"
            leftIcon={<users.icon />}
            onClick={onMenuClick}
          />
          ),
          (permissions === 'admin' || permissions === 'superadmin')
          && (
          <MenuItemLink
            key="m2-0"
            to="/style_packs"
            primaryText="Style Packs"
            leftIcon={<style_packs.icon />}
            onClick={onMenuClick}
          />
          ),
          (permissions === 'admin' || permissions === 'superadmin')
          && (
          <MenuItemLink
            key="m2"
            to="/styles"
            primaryText="Styles"
            leftIcon={<styles.icon />}
            onClick={onMenuClick}
          />
          ),
          <MenuItemLink
            key="m3"
            to="/events"
            primaryText="Events"
            leftIcon={<events.icon />}
            onClick={onMenuClick}
          />,
        ]
      )}
    />
  </div>
)

const mapStateToProps = state => ({
  resources: getResources(state),
})

export default withRouter(connect(mapStateToProps)(Menu))
