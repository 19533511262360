/* eslint-disable camelcase */
import React from 'react'
import { Admin, Resource } from 'react-admin'
import authProvider from './providers/authProvider'
import dataProvider from './providers/dataProvider'
import addUploadFeature from './providers/addUploadFeature'
import Menu from './components/layout/Menu'
import users from './components/users'
import events from './components/events'
import event_pictures from './components/event_pictures'
import styles from './components/styles'
import style_packs from './components/style_packs'

const uploadCapableDataProvider = addUploadFeature(dataProvider)

const App = () => (
  <Admin authProvider={authProvider} dataProvider={uploadCapableDataProvider} menu={Menu}>
    {permissions => [
      <Resource
        name="events"
        {...events}
        create={permissions === 'admin' || permissions === 'superadmin' ? events.create : null}
        edit={permissions === 'admin' || permissions === 'superadmin' ? events.edit : null}
      />,
      <Resource
        name="users"
        {...users}
        list={permissions === 'admin' || permissions === 'superadmin' ? users.list : null}
        create={permissions === 'admin' || permissions === 'superadmin' ? users.create : null}
        edit={permissions === 'admin' || permissions === 'superadmin' ? users.edit : null}
      />,
      <Resource
        name="style_packs"
        {...style_packs}
        create={permissions === 'admin' || permissions === 'superadmin' ? style_packs.create : null}
        edit={permissions === 'admin' || permissions === 'superadmin' ? style_packs.edit : null}
      />,
      <Resource
        name="styles"
        {...styles}
        list={permissions === 'admin' || permissions === 'superadmin' ? styles.list : null}
        create={permissions === 'admin' || permissions === 'superadmin' ? styles.create : null}
        edit={permissions === 'admin' || permissions === 'superadmin' ? styles.edit : null}
      />,
      <Resource
        name="event_pictures"
        {...event_pictures}
        create={permissions === 'admin' || permissions === 'superadmin' ? event_pictures.create : null}
      />,
    ]}
  </Admin>
)

export default App
