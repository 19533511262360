import React from 'react'
import { Show, SimpleShowLayout, TextField, ReferenceField, Labeled } from 'react-admin'
import PictureField from './PictureField'

const EventPictureShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Event" source="event_id" reference="events" linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="emails" label="Email" />

      <Labeled label="Picture" />
      <PictureField />
    </SimpleShowLayout>
  </Show>
)

export default EventPictureShow
