import {
  GET_LIST, GET_ONE, GET_MANY, GET_MANY_REFERENCE,
  CREATE, UPDATE, DELETE, fetchUtils,
} from 'react-admin'
import { stringify } from 'query-string'
import { singular } from 'pluralize'
import { ROOT_URL } from '../config/api'

/**
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  let apiPath = resource
  if (resource.indexOf('/') < 0) {
    apiPath = `cms/${resource}`
  }
  switch (type) {
    case GET_LIST: {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
        filter: JSON.stringify(params.filter),
        page,
        per_page: perPage,
      }
      return { url: `${ROOT_URL}/${apiPath}?${stringify(query)}`, options: {} }
    }
    case GET_ONE:
      return { url: `${ROOT_URL}/${apiPath}/${params.id}`, options: {} }
    case GET_MANY: {
      const query = {
        filter: JSON.stringify({ id: params.ids }),
      }
      return { url: `${ROOT_URL}/${apiPath}?${stringify(query)}`, options: {} }
    }
    case GET_MANY_REFERENCE: {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const query = {
        sort: JSON.stringify([field, order]),
        range: JSON.stringify([(page - 1) * perPage, (page * perPage) - 1]),
        filter: JSON.stringify({ ...params.filter, [params.target]: params.id }),
        page,
        per_page: perPage,
      }
      return { url: `${ROOT_URL}/${apiPath}?${stringify(query)}`, options: {} }
    }
    case UPDATE:
      return {
        url: `${ROOT_URL}/${apiPath}/${params.id}`,
        options: { method: 'PUT', body: JSON.stringify(params.data) },
      }
    case CREATE:
      return {
        url: `${ROOT_URL}/${apiPath}`,
        options: { method: 'POST', body: JSON.stringify(params.data) },
      }
    case DELETE:
      return {
        url: `${ROOT_URL}/${apiPath}/${params.id}`,
        options: { method: 'DELETE' },
      }
    default:
      throw new Error(`Unsupported fetch action type ${type}`)
  }
}

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top of this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The Data Provider request params, depending on the type
 * @returns {Object} Data Provider response
 */
const convertHTTPResponseToDataProvider = (response, type, resource, params) => {
  const dataRoot = resource.replace('cms/', '')
  const { json } = response
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE:
      return {
        data: json[dataRoot],
        total: json.meta.total_count || 10,
      }
    case UPDATE:
    case DELETE:
    case GET_ONE:
      return { data: json[singular(dataRoot)] }
    case GET_MANY:
      return { data: json[dataRoot] }
    case CREATE:
      return { data: { ...params.data, id: json[singular(dataRoot)].id, meta: json.meta } }
    default:
      return { data: json }
  }
}

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for response
 */
export default (type, resource, params) => {
  const { url, options } = convertDataProviderRequestToHTTP(type, resource, params)
  if (!options.headers) {
    options.headers = new Headers({ 'Content-Type': 'application/json' })
  }
  options.headers.set('Authorization', `Bearer ${localStorage.getItem('authToken')}`)
  return fetchUtils.fetchJson(url, options)
    .then(response => convertHTTPResponseToDataProvider(response, type, resource, params))
}
