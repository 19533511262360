import React from 'react'
import { Show, SimpleShowLayout, TextField, ReferenceArrayField, ImageField, SingleFieldList, ChipField } from 'react-admin'

const StyleShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="title" />
      <TextField source="styleid" />
      <TextField source="pack_name" />

      <ReferenceArrayField label="Style Packs" source="style_pack_ids" reference="style_packs">
        <SingleFieldList linkType="show">
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ImageField source="image_path" label="Image" />
    </SimpleShowLayout>
  </Show>
)

export default StyleShow
