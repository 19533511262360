import React from 'react'
import {
  Create, SimpleForm, TextInput, ImageInput, ImageField, ReferenceArrayInput, SelectArrayInput, DateInput, BooleanInput, LongTextInput, required,
} from 'react-admin'

const EventCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" validate={required()} />
      <DateInput source="event_date" validate={required()} />
      <TextInput source="street" />
      <TextInput source="suite" />
      <TextInput source="city" />
      <TextInput source="state" />
      <TextInput source="zip_code" />
      <TextInput source="email" />
      <BooleanInput source="printer_activated" />
      <BooleanInput source="active" />
      <ReferenceArrayInput label="Style Packs" source="style_pack_ids" reference="style_packs" perPage={1000}>
        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Styles" source="style_ids" reference="styles" perPage={1000}>
        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput>
      <TextInput source="tag_info" />
      <TextInput source="tag_line" />
      <TextInput source="email_subject" />
      <LongTextInput source="email_body" />

      <ImageInput source="image" label="Image" accept="image/*">
        <ImageField source="src" title="name" />
      </ImageInput>
      <ImageInput source="company_logo" accept="image/*">
        <ImageField source="src" title="company_logo_name" />
      </ImageInput>
    </SimpleForm>
  </Create>
)

export default EventCreate
