/* eslint-disable no-console, react/prop-types, no-shadow */
import React from 'react'
import { List, Datagrid, TextField, ImageField, ReferenceField, DeleteButton, ShowButton, downloadCSV, CardActions, CreateButton, ExportButton } from 'react-admin'
import { unparse as convertToCSV } from 'papaparse/papaparse.min'
import queryString from 'query-string'
import CustomCreateButton from './CustomCreateButton'

const exporter = (records, fetchRelatedRecords) => {
  fetchRelatedRecords(records, 'event_picture_detail_ids', 'event_picture_details').then((details) => {
    const data = []
    records.forEach((record) => {
      record.event_picture_detail_ids.forEach((detailId) => {
        data.push(details[detailId])
      })
    })
    const csv = convertToCSV({
      data,
      fields: ['name', 'email', 'event_name'],
    })
    downloadCSV(csv, `emails-${new Date().getTime()}`)
  })
}

const EventPictureActions = ({
  bulkActions, basePath, currentSort, displayedFilters, exporter, filters,
  filterValues, onUnselectItems, resource, selectedIds, showFilter, total,
}) => {
  const { hash } = window.location
  let eventId = null
  if (hash.indexOf('?') >= 0) {
    const parsed = queryString.parse(hash.substr(hash.indexOf('?')))
    if (parsed.filter) {
      eventId = JSON.parse(parsed.filter).event_id
    }
  }
  return (
    <CardActions>
      {bulkActions && React.cloneElement(bulkActions, {
        basePath, filterValues, resource, selectedIds, onUnselectItems,
      })}
      {filters && React.cloneElement(filters, {
        resource, showFilter, displayedFilters, filterValues, context: 'button',
      }) }
      { !eventId && <CreateButton basePath={basePath} /> }
      { eventId && <CustomCreateButton basePath={basePath} eventId={eventId} /> }
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
    </CardActions>
  )
}

const EventPictureList = ({ permissions, ...props }) => (
  <List {...props} exporter={exporter} bulkActionButtons={false} actions={<EventPictureActions />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <ReferenceField label="Event" source="event_id" reference="events" linkType="show">
        <TextField source="name" />
      </ReferenceField>
      <ImageField source="picture_path" label="Picture" sortable={false} />
      <TextField source="emails" label="Email" />

      <ShowButton label="" />
      {(permissions === 'admin' || permissions === 'superadmin') && <DeleteButton label="" undoable={false} />}
    </Datagrid>
  </List>
)

export default EventPictureList
