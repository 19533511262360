/* eslint-disable no-console, react/prop-types */
import React from 'react'
import { List, Datagrid, TextField, DateField, EditButton, DeleteButton, ShowButton } from 'react-admin'
import PicturesButton from './PicturesButton'

const EventList = ({ permissions, ...props }) => (
  <List {...props} exporter={false} bulkActionButtons={false} sort={{ field: 'event_date', order: 'DESC' }}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="event_date" />

      {(permissions === 'admin' || permissions === 'superadmin') && <EditButton label="" />}
      <ShowButton label="" />
      {(permissions === 'admin' || permissions === 'superadmin') && <DeleteButton label="" undoable={false} />}
      <PicturesButton label="" />
    </Datagrid>
  </List>
)

export default EventList
