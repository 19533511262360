import React from 'react'
import {
  Create, SimpleForm, TextInput, ReferenceArrayInput, SelectArrayInput, ImageInput, ImageField, required,
} from 'react-admin'

const StyleCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" validate={required()} />
      <ReferenceArrayInput label="Style Packs" source="style_pack_ids" reference="style_packs" validate={required()} perPage={1000}>
        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput>
      <ImageInput source="image" label="Image" accept="image/*">
        <ImageField source="src" title="name" />
      </ImageInput>
    </SimpleForm>
  </Create>
)

export default StyleCreate
