/* eslint-disable no-console */

import React from 'react'
import { List, Datagrid, TextField, EditButton, DeleteButton, ShowButton } from 'react-admin'

const UserList = props => (
  <List {...props} exporter={false} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="email" />

      <EditButton label="" />
      <ShowButton label="" />
      <DeleteButton label="" undoable={false} />
    </Datagrid>
  </List>
)

export default UserList
