/* eslint-disable react/prop-types */
import React from 'react'
import {
  Create, SimpleForm, ImageInput, ImageField, ReferenceInput, SelectInput, required,
} from 'react-admin'
import queryString from 'query-string'

const EventPictureCreate = (props) => {
  const { location, location: { search } } = props
  const paramsParsed = queryString.parse(search)
  if (paramsParsed.event_id) {
    location.state = { record: { event_id: parseInt(paramsParsed.event_id, 10) } }
  }
  return (
    <Create {...props}>
      <SimpleForm>
        <ReferenceInput label="Event" source="event_id" reference="events" validate={required()}>
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ImageInput source="image" label="Picture" accept="image/*">
          <ImageField source="src" title="name" />
        </ImageInput>
      </SimpleForm>
    </Create>
  )
}

export default EventPictureCreate
