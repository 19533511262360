import React from 'react'
import { Show, SimpleShowLayout, TextField, ReferenceArrayField, SingleFieldList, ChipField, ImageField } from 'react-admin'

const UserShow = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="email" />
      <ReferenceArrayField label="Events" source="event_ids" reference="events">
        <SingleFieldList linkType="show">
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ImageField source="avatar_path" label="Avatar" />
    </SimpleShowLayout>
  </Show>
)

export default UserShow
