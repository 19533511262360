import EventIcon from '@material-ui/icons/Event'
import EventList from './EventList'
import EventShow from './EventShow'
import EventEdit from './EventEdit'
import EventCreate from './EventCreate'

export default {
  list: EventList,
  show: EventShow,
  edit: EventEdit,
  create: EventCreate,
  icon: EventIcon,
}
