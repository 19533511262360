import React from 'react'
import {
  Edit, SimpleForm, TextInput, DisabledInput, ReferenceArrayInput, SelectArrayInput, ImageInput, ImageField, required,
} from 'react-admin'

const UserEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="email" validate={required()} />
      <TextInput source="password" type="password" />
      <ReferenceArrayInput label="Events" source="event_ids" reference="events" perPage={1000}>
        <SelectArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ImageInput source="image" label="Image" accept="image/*">
        <ImageField source="src" title="name" />
      </ImageInput>
      <ImageField source="avatar_path" label="" />
    </SimpleForm>
  </Edit>
)

export default UserEdit
