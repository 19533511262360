/* eslint-disable react/prop-types */
import React from 'react'
import { Show, SimpleShowLayout, TextField, DateField, BooleanField, ImageField, ReferenceManyField, ReferenceArrayField, SingleFieldList, ChipField, Labeled } from 'react-admin'
import { withStyles } from '@material-ui/core/styles'
import GridList from './PictureGridList'
import EventTitle from './EventTitle'

const styles = {
  newLine: {
    whiteSpace: 'pre-line',
  },
}

const EventShow = ({ classes, ...props }) => (
  <Show {...props} title={<EventTitle />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <DateField source="event_date" />
      <TextField source="street" />
      <TextField source="suite" />
      <TextField source="city" />
      <TextField source="state" />
      <TextField source="zip_code" />
      <TextField source="email" />
      <BooleanField source="printer_activated" />
      <BooleanField source="active" />
      <TextField source="tag_info" />
      <TextField source="tag_line" />
      <TextField source="email_subject" />
      <TextField source="email_body" className={classes.newLine} />
      <ImageField source="image_path" label="Image" />
      <ImageField source="company_logo_path" label="Company logo" />
      <ReferenceArrayField label="Styles" source="style_ids" reference="styles">
        <SingleFieldList linkType="show">
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>

      <Labeled label="Pictures" />
      <ReferenceManyField
        addLabel={false}
        reference="event_pictures"
        target="event_id"
        sort={{ field: 'id', order: 'DESC' }}
        perPage={500}
      >
        <GridList />
      </ReferenceManyField>

    </SimpleShowLayout>
  </Show>
)

export default withStyles(styles)(EventShow)
