import React from 'react'
import {
  Create, SimpleForm, TextInput, LongTextInput, required,
} from 'react-admin'

const StylePackCreate = props => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" validate={required()} />
      <LongTextInput source="description" />
    </SimpleForm>
  </Create>
)
export default StylePackCreate
