import React from 'react'
import {
  Edit, SimpleForm, TextInput, DisabledInput, ReferenceArrayInput, SelectArrayInput, ImageInput, ImageField, required,
} from 'react-admin'

const StyleEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="title" validate={required()} />
      <ReferenceArrayInput label="Style Packs" source="style_pack_ids" reference="style_packs" validate={required()} perPage={1000}>
        <SelectArrayInput optionText="title" />
      </ReferenceArrayInput>
      <ImageInput source="image" label="Image" accept="image/*">
        <ImageField source="src" title="name" />
      </ImageInput>
      <ImageField source="image_path" label="" />
    </SimpleForm>
  </Edit>
)

export default StyleEdit
