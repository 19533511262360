import EventPictureIcon from '@material-ui/icons/Photo'
import EventPictureList from './EventPictureList'
import EventPictureShow from './EventPictureShow'
import EventPictureCreate from './EventPictureCreate'

export default {
  list: EventPictureList,
  show: EventPictureShow,
  create: EventPictureCreate,
  icon: EventPictureIcon,
}
