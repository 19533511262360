import React from 'react'
import PropTypes from 'prop-types'

const EventTitle = ({ record }) => (
  <span>
    {record.name}
  </span>
)

EventTitle.propTypes = {
  record: PropTypes.object,
}

EventTitle.defaultProps = { record: {} }

export default EventTitle
