/* eslint-disable react/prop-types */
import React from 'react'
import compose from 'recompose/compose'
import MuiGridList from '@material-ui/core/GridList'
import GridListTile from '@material-ui/core/GridListTile'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import { CANVAS_URL } from '../../config/api'

const styles = theme => ({
  root: {
    margin: '-2px',
  },
  gridList: {
    width: '100%',
    margin: 0,
  },
  tileBar: {
    background:
            'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
  link: {
    color: '#fff',
  },
})

const open2ImagesCanvas = (url1, url2) => {
  window.open(`${CANVAS_URL}?url1=${encodeURIComponent(url1)}&url2=${encodeURIComponent(url2)}`, '_blank')
}

const getColsForWidth = (width) => {
  if (width === 'xs') return 2
  if (width === 'sm') return 3
  if (width === 'md') return 4
  if (width === 'lg') return 5
  return 6
}

const times = (nbChildren, fn) => Array.from({ length: nbChildren }, (_, key) => fn(key))

const LoadingGridList = ({ width, classes, nbItems = 10 }) => (
  <div className={classes.root}>
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {' '}
      {times(nbItems, key => (
        <GridListTile key={key}>
          <div className={classes.placeholder} />
        </GridListTile>
      ))}
    </MuiGridList>
  </div>
)

const LoadedGridList = ({ classes, ids, data, width }) => (
  <div className={classes.root}>
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {ids.map(id => (
        <GridListTile key={id} onClick={() => open2ImagesCanvas(data[id].picture_path, data[id].company_logo_path)}>
          <img src={data[id].picture_path} alt="" />
          <GridListTileBar
            className={classes.tileBar}
            title={data[id].email}
          />
        </GridListTile>
      ))}
    </MuiGridList>
  </div>
)

const PictureGridList = ({ loadedOnce, ...props }) => (loadedOnce ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />)

const enhance = compose(
  withWidth(),
  withStyles(styles),
)

export default enhance(PictureGridList)
