/**
 * Convert a `File` object returned by the upload input into a base 64 string.
 */
const convertFileToBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file.rawFile)

  reader.onload = () => resolve(reader.result)
  reader.onerror = reject
})

const addUploadFeature = requestHandler => (type, resource, params) => {
  if (['CREATE', 'UPDATE'].includes(type) && ['event_pictures', 'styles', 'users'].includes(resource)) {
    if (params.data.image) {
      return addGeneralUploadFeature(requestHandler, type, resource, params)
    }
  } else if (['CREATE', 'UPDATE'].includes(type) && resource === 'events') {
    if (params.data.image || params.data.company_logo) {
      return addUploadFeatureForEvent(requestHandler, type, resource, params)
    }
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params)
}

const addGeneralUploadFeature = (requestHandler, type, resource, params) => {
  const formerPictures = [params.data.image].filter(p => !(p.rawFile instanceof File))
  const newPictures = [params.data.image].filter(p => p.rawFile instanceof File)

  return Promise.all(newPictures.map(convertFileToBase64))
    .then(base64Pictures => base64Pictures.map((picture64, index) => ({
      src: picture64,
      name: `${newPictures[index].name}`,
    })))
    .then((transformedNewPictures) => {
      const images = [...transformedNewPictures, ...formerPictures]
      let paramName = resource === 'event_pictures' ? 'picture' : 'image'
      const imgParam = {}
      if (resource === 'users') {
        paramName = 'avatar'
      }
      imgParam[paramName] = images[0].src
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          ...imgParam,
        },
      })
    })
}

const addUploadFeatureForEvent = (requestHandler, type, resource, params) => {
  const rawImages = [params.data.image, params.data.company_logo].filter(p => p != null)
  const formerPictures = rawImages.filter(p => !(p.rawFile instanceof File))
  const newPictures = rawImages.filter(p => p.rawFile instanceof File)

  return Promise.all(newPictures.map(convertFileToBase64))
    .then(base64Pictures => base64Pictures.map((picture64, index) => ({
      src: picture64,
      name: `${newPictures[index].name}`,
      company_logo_name: `${newPictures[index].company_logo_name}`,
    })))
    .then((transformedNewPictures) => {
      const images = [...transformedNewPictures, ...formerPictures]
      const imgParam = {}
      images.forEach((img) => {
        if (img.src) {
          const paramName = img.company_logo_name !== 'undefined' ? 'company_logo' : 'image'
          imgParam[paramName] = img.src
        }
      })
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          ...imgParam,
        },
      })
    })
}

export default addUploadFeature
