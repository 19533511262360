import React from 'react'
import {
  Edit, SimpleForm, TextInput, DisabledInput, LongTextInput, required,
} from 'react-admin'

const StylePackEdit = props => (
  <Edit {...props} undoable={false}>
    <SimpleForm>
      <DisabledInput source="id" />
      <TextInput source="title" validate={required()} />
      <LongTextInput source="description" />
    </SimpleForm>
  </Edit>
)

export default StylePackEdit
