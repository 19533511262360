/* eslint-disable react/require-default-props, no-unused-vars */
import React from 'react'
import PropTypes from 'prop-types'
import shouldUpdate from 'recompose/shouldUpdate'
import PhotoIcon from '@material-ui/icons/Photo'
import { Link } from 'react-router-dom'
import { Button } from 'react-admin'
import { stringify } from 'query-string'

// useful to prevent click bubbling in a datagrid with rowClick
const stopPropagation = e => e.stopPropagation()

const PicturesButton = ({
  basePath = '',
  label = 'Pictures',
  record = {},
  icon = <PhotoIcon />,
  ...rest
}) => (
  <Button
    component={Link}
    to={{ pathname: '/event_pictures',
      search: stringify({
        filter: JSON.stringify({ event_id: record.id }),
      }),
    }}
    label={label}
    onClick={stopPropagation}
    {...rest}
  >
    {icon}
  </Button>
)

PicturesButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  icon: PropTypes.element,
}

const enhance = shouldUpdate(
  (props, nextProps) => props.translate !== nextProps.translate
        || (props.record
            && nextProps.record
            && props.record.id !== nextProps.record.id)
            || props.basePath !== nextProps.basePath
           || (props.record == null && nextProps.record != null),
)

export default enhance(PicturesButton)
