import StylePackIcon from '@material-ui/icons/FolderOpen'
import StylePackList from './StylePackList'
import StylePackShow from './StylePackShow'
import StylePackEdit from './StylePackEdit'
import StylePackCreate from './StylePackCreate'

export default {
  list: StylePackList,
  show: StylePackShow,
  edit: StylePackEdit,
  create: StylePackCreate,
  icon: StylePackIcon,
}
