import { AUTH_LOGIN, AUTH_CHECK, AUTH_GET_PERMISSIONS, AUTH_ERROR, AUTH_LOGOUT } from 'react-admin'
import { constructUrlEndPoint, API_ENDPOINTS } from '../config/api'

export default (type, params) => {
  if (type === AUTH_LOGIN) {
    const { username, password } = params
    const request = new Request(constructUrlEndPoint(API_ENDPOINTS.auth.signIn), {
      method: 'POST',
      body: JSON.stringify({ auth: { email: username, password } }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(response => response.json())
      .then((json) => {
        if (json.errors) {
          throw Error(json.errors[0].message)
        }
        const { id, role } = json.user
        localStorage.setItem('authToken', json.meta.jwt)
        localStorage.setItem('authRole', role)
        localStorage.setItem('authId', id)
      })
  }
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('authToken')
    localStorage.removeItem('authRole')
  }

  if (type === AUTH_CHECK) {
    const role = localStorage.getItem('authRole')
    if (!role) {
      return Promise.reject()
    }
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('authRole')
    /*
    if (role && !(role === 'admin' || role === 'superadmin')) {
      return Promise.reject()
    }
    */
    return Promise.resolve(role)
  }
  if (type === AUTH_ERROR) {
    const { status } = params
    if (status === 401 || status === 403) {
      localStorage.removeItem('authToken')
      localStorage.removeItem('authRole')
      return Promise.reject()
    }
    return Promise.resolve()
  }

  return Promise.resolve()
}
