import UserIcon from '@material-ui/icons/People'
import UserList from './UserList'
import UserShow from './UserShow'
import UserEdit from './UserEdit'
import UserCreate from './UserCreate'

export default {
  list: UserList,
  show: UserShow,
  edit: UserEdit,
  create: UserCreate,
  icon: UserIcon,
}
