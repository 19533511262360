import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { CANVAS_URL } from '../../config/api'

const styles = {
  root: {
    margin: '0.5rem',
    maxHeight: '10rem',
  },
}

const PictureField = withStyles(styles)(({ classes, record }) => (
  <a href={`${CANVAS_URL}?url1=${encodeURIComponent(record.picture_path)}&url2=${encodeURIComponent(record.company_logo_path)}`} target="_blank" rel="noopener noreferrer">
    <img src={record.picture_path} className={classes.root} alt="" />
  </a>
))

export default PictureField
