/* eslint-disable no-console, react/prop-types */

import React from 'react'
import { List, Datagrid, TextField, EditButton, DeleteButton, ShowButton } from 'react-admin'

const StylePackList = ({ permissions, ...props }) => (
  <List {...props} exporter={false} bulkActionButtons={false}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="title" />

      {(permissions === 'admin' || permissions === 'superadmin') && <EditButton label="" />}
      <ShowButton label="" />
      {(permissions === 'admin' || permissions === 'superadmin') && <DeleteButton label="" undoable={false} />}
    </Datagrid>
  </List>
)

export default StylePackList
