/* eslint-disable no-console */

import React from 'react'
import { List } from 'react-admin'
import GridList from './GridList'

const StyleList = props => (
  <List {...props} exporter={false} bulkActionButtons={false}>
    <GridList />
  </List>
)

export default StyleList
